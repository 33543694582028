<template>
  <div class="wifi">
    <div class="logo">
      <img src="@/assets/images/wifi/image-logo.svg" alt="" />
    </div>

    <div class="image text-center">
      <img src="@/assets/images/wifi/img-header.svg" alt="" />
    </div>

    <h2>Bạn đang muốn truy cập vào wifi<span v-if="ssid"> {{ ssid }}</span>?</h2>

    <p>Hãy làm theo các bước sau:</p>

    <div class="list-items">
      <div class="item">
        <div class="order">01</div>

        <h4>Tải ứng dụng VinID</h4>

        <a :href="url" class="btn">Tải ứng dụng VinID</a>
      </div>

      <div class="item">
        <div class="order">02</div>

        <h4>Mở VinID để quét mã</h4>

        <p>Mở ứng dụng <strong>VinID</strong>, chọn <strong>Quét mã</strong> và đặt camera trước mã QR Wi-Fi.</p>

        <div class="image text-center">
          <img src="@/assets/images/wifi/img-scan-qr.png" alt="" />
        </div>
      </div>

      <div class="item">
        <div class="order">03</div>

        <h4>Tận hưởng</h4>

        <p>Ứng dụng <strong>VinID</strong> sẽ giúp bạn tự động kết nối Wi-Fi. Bây giờ, bạn có thể tận hưởng những trải nghiệm mua sắm tuyệt vời tại VinMart như <strong>Scan &amp; Go</strong> mà không lo mất kết nối.</p>
      </div>
    </div>

    <div class="image text-center">
      <img src="@/assets/images/wifi/image-footer.svg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Wifi',

  data () {
    return {
      ssid: '',
      url: "https://vinid.page.link"
    }
  },

  created () {
    if (this.$route.params.ssid) {
      const urlParam = this.$route.params.ssid
      const leftUrl = urlParam.substring(0, urlParam.indexOf(';') > 0 ? urlParam.indexOf(';') : urlParam.length)
      const ssid = leftUrl.substring(leftUrl.lastIndexOf(':') + 1, leftUrl.length)

      this.ssid = ssid
    }

    if (this.$route.fullPath) {
      const fullPath = this.$route.fullPath
      const leftPath = fullPath.substring(0, fullPath.indexOf(';') > 0 ? fullPath.indexOf(';') : fullPath.length)

      this.url =
        "https://vinid.page.link/?link=https://qr.id.vin" +
        leftPath +
        "&apn=com.vingroup.vinid&isi=1215495998&ibi=com.vingroup.VinIDApp";
    }
  }
}
</script>

<style lang="scss" scoped>
.wifi {
  padding: 25px 0;
}

.image {
  margin: 15px 0;

  img {
    max-width: 100%;
  }
}

.list-items {
  .item {
    overflow: hidden;
    padding-left: 55px;
    position: relative;

    .order {
      background: #ffbcbc;
      border-radius: 8px;
      color: #ee1d1d;
      font-size: 18px;
      font-weight: 600;
      height: 40px;
      left: 0;
      line-height: 40px;
      position: absolute;
      text-align: center;
      top: 0;
      vertical-align: middle;
      width: 40px;
    }

    h4 {
      margin-top: 10px;
    }

    + .item {
      margin-top: 30px;
    }
  }
}

p {
  color: #666;
  font-size: 14px;
}

.btn {
  background: #d6e8ff;
  border-radius: 8px;
  border: 1px solid #d6e8ff;
  color: #2987fd;
  display: block;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 0 8px;
  text-align: center;
  transition: all ease 300ms;

  &:hover {
    background: transparent;
    text-decoration: none;
  }
}
</style>
